import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { useTranslation } from './TranslationContext'; // Import useTranslation

import './style.css';

const Footer = () => {
  const { translate } = useTranslation();
  const [apiUrl] = useState(localStorage.getItem('api_url'));
  const [userId] = useState(localStorage.getItem('admin_id'));
  const [email, setEmail] = useState('');
  const [partnerEmail, setPartnerEmail] = useState('');
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [type, setType] = useState('');
  const [errors, setErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);

  // const toggleModal = () => {
  //   setOpenModal(!openModal);
  // };

  const toggleModal5 = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "") {
      alert(translate('emailEmpty'));
    } else if (!emailRegex.test(email)) {
      alert(translate('invalidEmailFormat'));
    } else {
      alert(translate('subscribeSuccess'));
      window.location.reload();
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors = {};
  //   if (!name) validationErrors.name = translate('nameRequired');
  //   if (!mobile) validationErrors.mobile = translate('mobileRequired');
  //   if (!partnerEmail) validationErrors.partnerEmail = translate('emailRequired');
  //   if (!type) validationErrors.type = translate('typeRequired');

  //   setErrors(validationErrors);

  //   if (Object.keys(validationErrors).length === 0) {
  //     try {
  //       const response = await axios.post(`${apiUrl}/beingpatner`, {
  //         name,
  //         mobile,
  //         email: partnerEmail,
  //         type
  //       });

  //       if (response.data.status) {
  //         alert(translate('partnerAlert'));
  //         setOpenModal(false);
  //         setName('');
  //         setMobile('');
  //         setPartnerEmail('');
  //         setType('');
  //       }
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //     }
  //   }
  // };

  useEffect(() => {
    // Dynamically load the Tawk.to script
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function(){
      var s1 = document.createElement("script");
      var s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/66bf5e4c0cca4f8a7a76cc86/1i5dognau';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <div className="footer">
      <div className='row'>
        <div className='col-md-3'>
          <div className="quicklink">{translate('quickLinks')}</div>
          <br />
          <p className="support-center">
            <Link to="/support-center">{translate('supportCenter')}</Link><br />
            <Link to="/policy">{translate('policy')}</Link><br />
            <Link to="/terms-conditions">{translate('termsConditions')}</Link><br />
            <Link to="/shipping">{translate('shipping')}</Link><br />
            <Link to="/return">{translate('return')}</Link><br />
            <Link to="/register-seller">{translate('registerSeller')}</Link><br />
            <Link to="/faqs">{translate('faqs')}</Link>
          </p>
        </div>
        <div className='col-md-3'>
          <div className="company">{translate('aboutUs')}</div>
          <br />
          <p className="about-us-team-member">
            <Link to="/about-us">{translate('aboutUs')}</Link><br />
            <Link to="/career">{translate('career')}</Link><br />
            <Link to="/contact-us">{translate('contactUs')}</Link><br />
            <Link to="/affiliate">{translate('affiliate')}</Link>
          </p>
        </div>
        <div className='col-md-3'>
          <div className="markplace">{translate('sellOnMarketPlace')}</div>
          <br />
          <p className="sell-on-market-place">
            <Link to="/sell-on-market-place">{translate('sellOnMarketPlace')}</Link><br />
            <Link to="/advertises-on-market-place">{translate('advertisesOnMarketPlace')}</Link><br />
          </p>
        </div>
        <div className='col-md-3'>
          <div className="newsletter">{translate('newsletter')}</div>
          <br />
          <p className="registernow">{translate('registerNow')}</p>
          <div className='email_check'>
            <input
              className="email_here"
              type="email"
              id="email"
              placeholder={translate('enterEmail')}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <button className="subscribe" onClick={toggleModal5}>{translate('subscribe')}</button>
          </div>
          <div className="followus">{translate('followUs')}</div>
          <div className='social_ions'>
            <a href="https://www.linkedin.com" target="_blank" title="Linkedin" rel="noopener noreferrer">
              <img className="linkedin" alt="Linkedin" src={require('./img/linkedin.png')} />
            </a>
            <a href="https://www.facebook.com" target="_blank" title="Facebook" rel="noopener noreferrer">
              <img className="facebook-logo" alt="Facebook logo" src={require('./img/facebook-logo.png')} />
            </a>
            <a href="https://www.twitter.com" target="_blank" title="Twitter" rel="noopener noreferrer">
              <img className="twitter" alt="Twitter" src={require('./img/twitter.png')} />
            </a>
            <a href="https://www.youtube.com" target="_blank" title="Youtube" rel="noopener noreferrer">
              <img className="youtube" alt="Youtube" src={require('./img/youtube.png')} />
            </a>
          </div>
        </div>
      </div>
     
  
  <hr />
        <div className='row'>
          <div className='col-md-6'>
            <div className="copyright">{translate('copyright')}</div>
          </div>
          <div className='col-md-3'>
            <p className="payment-text">{translate('paymentText')}</p>
          </div>
          <div className='col-md-2'>
            <img className="payment_img" alt="" src={require('./img/image_35.png')} />
          </div>
        </div>
   </div> ); };

export default Footer;