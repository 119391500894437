import React, { useState, useEffect } from 'react';
import './style.css';
import Header_home from './Header_home';
import Footer from './Footer';
import Menu from './Menu';
import axios from "axios";
import Prductslide from './Prductslide';
import Prductslide3 from './Prductslide3';

import Catslide from './Catslide';
import Bannerslideshow from './Bannerslideshow';
import Cookies from 'js-cookie';
import "./Prductslide.css";
import Product_rating from './Product_rating';
import { useTranslation } from './TranslationContext';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [state, setState] = useState({
    api_url: localStorage.getItem('api_url'),
    data: [],
    coo_product_id: localStorage.getItem('coo_product_id'),
    coo_product_name: localStorage.getItem('coo_product_name'),
    coo_product_image: localStorage.getItem('coo_product_image'),
    coo_price: localStorage.getItem('coo_price'),
    coo_quantity: localStorage.getItem('coo_quantity'),
    prescription: 'no',
    search: '',
    suc_cls: '',
    errors: {},
    openModal: false,
    pic: '',
    fileName: '',
    email: '',
    email1: '',
    password: ''
  });

  const { translate } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("product_id", id);
    navigate('/Detail_page');
  };

  const toggleModal = () => {
    setState(prevState => ({
      ...prevState,
      openModal: !prevState.openModal
    }));
  };

  useEffect(() => {
    // const api_url = localStorage.getItem('api_url');
    // axios.get(`${api_url}/gettrandproduct/`).then(response => console.log(response.data));
  }, []);

  return (
    <div className="">
      <Header_home />
      {/* <Menu /> */} 
      <br /><br />
      <Bannerslideshow />
      <br /><br /><br />
      <div className='sell_best_product'>
      <br /><br />
        <h2 className="" style={{textAlign:"left",marginLeft:"11%"}}>{translate('shopByCategory')}</h2>
        <br /><br />
        <Catslide />
        <br /><br /><br /><br />
      </div>
      <br /><br /><br /><br />
      <img className="final_img" alt="Group" src={require('./img/image_back.png')} />
      <br /><br /><br /><br /><br />
      <div className='sell_best_product'>
        <br /><br />
        <h2 className="" style={{textAlign:"left",marginLeft:"1%"}}>{translate('bestSellerProducts')}</h2>
        <br /><br />
        <Prductslide />
        <br /><br /><br />
      </div>
      
      <br /><br /><br /><br />
      <div>
        <img className="final_img" alt="Group" src={require('./img/img_back2.png')} />
        <br /><br />
      </div>
      <br /><br />
      <div className='sell_best_product'>
        <br /><br />
        <h2 className="" style={{textAlign:"left",marginLeft:"1%"}}>{translate('trendingProducts')}</h2>
        <br />
        <Prductslide />
        <br /><br /><br />
      </div>

      <br /><br />
      {/* <div className='sell_best_product'>
        <br /><br />
        <h2 className="">{translate('locationandmonthwiseproducts')}</h2>
        <br />
        <Prductslide3 />
        <br /><br /><br />
      </div> */}
      <Footer />
    </div>
  );
};

export default Home;
