import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./style.css";
import Product_rating from "./Product_rating";
import { useTranslation } from './TranslationContext';


const Product_detail = () => {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('Sort By Position');
  const [selectedOption1, setSelectedOption1] = useState('10');
  const [viewMode, setViewMode] = useState('grid'); // Default view mode is grid
  const { translate } = useTranslation();


  const handleDivClick = (id) => {
    localStorage.setItem("product_id", id);
    navigate("/Detail_page", { replace: true });
  };

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    let sortedCards = [...cards];
  
    if (e.target.value === "1") { // A to Z
      sortedCards.sort((a, b) => a.product_name.localeCompare(b.product_name));
    } else if (e.target.value === "2") { // Z to A
      sortedCards.sort((a, b) => b.product_name.localeCompare(a.product_name));
    }
  
    setCards(sortedCards);
  };

  const handleChange1 = (e) => {
    setSelectedOption1(e.target.value);
    // Filter the products based on the selected count
    let filteredCards = [...cards];
    if (e.target.value !== 'all') {
      filteredCards = filteredCards.slice(0, parseInt(e.target.value));
    }
    setCards(filteredCards);
  };

  const handlegirdview = () => {
    setViewMode('grid');
  };

  const handlelistview = () => {
    setViewMode('list');
  };


  useEffect(() => {
    const fetchCards = async () => {
      try {
        const api_url = localStorage.getItem('api_url');
        const category_id = localStorage.getItem('category_id');
        const response = await axios.get(api_url + '/admingetProducts_frontend6/'+category_id);
        setCards(response.data.productss);
        console.log(response.data.productss)
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []);

 
  return (
    <div class="items">
                                        
                    <div class="items1">
                <div className='first_view'>
                  <div className='tot_item'>{translate('items')}</div>
                    <div className='item_pos'>
                        <select name="sot_pos" className='sot_pos' value={selectedOption} onChange={handleChange} >
                            <option>Sort By Position</option>
                            <option value="1">A to Z</option>
                            <option value="2">Z to A</option> 
                        </select>
                    </div>
                    <br></br>
                    
                    <br></br>
                    <div className='item_pos_show'>
                    <select name="sot_pos1" className='sot_pos1' value={selectedOption1} onChange={handleChange1}>
                            <option value="10">Show 10</option>
                            <option value="20">Show 20</option>
                            <option value="30">Show 30</option>
                            <option value="40">Show 40</option>
                            <option value="50">Show 50</option>
                            <option value="all">Show All</option>
                        </select>
                    </div>
                </div>
                    


                    <div className='last_view'>
                        <div className='item_view'>{translate('view')}</div>
                        <br></br>
                        <div className='item_view_box'>
                        <img className="twitter1" alt="Twitter" title="grid view" src={require('./img/grid.png')} onClick={handlegirdview} />
                          </div>
                        <br></br>
                        <div className='item_view_menu'>
                        {/* <img className="twitter2" alt="Twitter" title="list view" src={require('./img/list.png')}  onClick={handlelistview}/> */}
                        </div>
                    </div>
            </div> 
            <br></br>
            <div class={`container1 ${viewMode === 'grid' ? 'grid-view' : 'list-view'}`}>
        {cards.map((product) =>(
          <div class={`card ${viewMode === 'grid' ? 'grid-view' : 'list-view'}`}>
            <img className="pro_card_view" alt="Logo" src={product.product_image} onClick={() => handleDivClick(product.product_id)}/>
            <div className='pro_Cr'>
              <p style={{marginLeft:"17px"}} onClick={() => handleDivClick(product.product_id)}>{product.product_name}</p>
              <div class="rating">
              <Product_rating rating={cards.rating} />
              </div>
              <div className="price-details" style={{marginLeft:"17px"}}>
                {product.discount_price && product.discount_price !== product.price ? (
                  <>
                    <p className="pro_pz original-price" ><s>{product.price} Rs</s></p>
                    <p className="pro_pz discounted-price">{product.discount_price} Rs</p>
                  </>
                ) : (
                  <p className="pro_pz" >{product.price} Rs</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default Product_detail;
