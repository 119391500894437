import React, { useState, useEffect } from "react";
import './Detail_tab.css'; // Import the CSS file for styling
import axios from "axios";
import Productuser_review from "./Productuser_review";
import { useTranslation } from './TranslationContext';


const Detail_tab = () => {
  const [activeTab, setActiveTab] = useState('tab1'); // State to track the active tab
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState([]);
  const [cards2, setCards2] = useState([]);
  const [response, setResponse] = useState({ positive: '', negative: '' }); // State to store sentiment analysis response
  const { translate } = useTranslation();

  const [response1, setResponse1] = useState('');
  const [generatedText, setGeneratedText] = useState('');

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Update the active tab when a tab is clicked
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const product_id = localStorage.getItem('product_id');
        const api_url = localStorage.getItem('api_url');
        const response = await axios.get(`${api_url}/getproduct_detail/${product_id}`);
        setCards(response.data.productdetails);
        setCards1(response.data.productattribute);
        setCards2(response.data.products);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchProductDetails();
  }, []);

  useEffect(() => {
    const fetchSentimentAnalysis = async () => {
      if (cards1.length > 0) {
        try {
          const result = await axios.post(
            'https://api-inference.huggingface.co/models/distilbert-base-uncased-finetuned-sst-2-english',
            {
              inputs: cards2.product_name,
            },
            {
              headers: {
                Authorization: `Bearer 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`,
              },
            }
          );
          
          // Parse the sentiment response
         // Parse the sentiment response
         const sentimentData = result.data[0];
         let positiveScore = '';
         let negativeScore = '';
         
         sentimentData.forEach((sentiment) => {
           if (sentiment.label === "POSITIVE") {
             positiveScore = (sentiment.score * 100).toFixed(2) + '%';
           } else if (sentiment.label === "NEGATIVE") {
             negativeScore = (sentiment.score * 100).toFixed(2) + '%';
           }
         });

         setResponse({ positive: positiveScore, negative: negativeScore });

         try {
          const result = await axios.post(
            "https://api-inference.huggingface.co/models/gpt2",
            {
              inputs: cards2.product_name,
            },
            {
              headers: {
                Authorization: `Bearer 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`,
              },
            }
          );

          const generatedTextData = result.data[0]?.generated_text;
          setResponse1(generatedTextData || 'No text generated.');
          // setResponse1(result.data);
        } catch (error) {
          console.error('Error fetching data:', error);
          setResponse('Error fetching data.');
        }



        } catch (error) {
          console.error('Error fetching sentiment data:', error);
          setResponse({ positive: 'Error', negative: 'Error' });
        }
        


      }
    };

    fetchSentimentAnalysis();
  }, [cards1]); // Re-run sentiment analysis when cards1 changes



  return (
    <div className="tabs-container">
      <div className="tabs">
        <div
          className={activeTab === 'tab1' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab1')}
        >
          {translate('detail')}
        </div>
        <div
          className={activeTab === 'tab2' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab2')}
        >
         {translate('more')}
        </div>
        <div
          className={activeTab === 'tab3' ? 'tab active' : 'tab'}
          onClick={() => handleTabClick('tab3')}
        >
          {translate('review')}
        </div>
      </div>
      <div className="tab-content1">
        {activeTab === 'tab1' && (
          <div>
            <div className="detail_tab">
              {cards.map((card, index) => (
                <p key={index}><b>{card.spec_key} </b> <span className="detail_tab1">{card.spec_value}</span></p>
              ))}
              {cards1.length > 0 && (
                <>
                  <p><b>Brand </b> <span className="detail_tab1">{cards1[0].brand}</span></p>
                  <p><b>Nutrient  </b> <span className="detail_tab1">{cards1[0].nutrient}</span></p>
                  <p><b>Organic Certified </b> <span className="detail_tab1">{cards1[0].organiccertified}</span></p>
                  <p><b>Container Type </b> <span className="detail_tab1">{cards1[0].container_type}</span></p>
                  <p><b>Ingredients  </b> <span className="detail_tab1">{cards1[0].ingredients}</span></p>
                  <p><b>Expiry Date </b> <span className="detail_tab1">{cards1[0].expirydate}</span></p>
                  <p><b>Global Review Product Posivite count</b> <span className="detail_tab1">{response.positive}</span></p>
                  <p><b>Global Review Product Negative count </b> <span className="detail_tab1">{response.negative}</span></p>
                  <p><b>Other Info </b> <span className="detail_tab1">{response1}</span></p>

                </> 
              )}
            </div>
          </div>
        )}
        {activeTab === 'tab2' && (
          <div>
            <p className="more_info">{cards1.length > 0 ? cards1[0].ingredients : ''}</p>
          </div>
        )}
        {activeTab === 'tab3' && (
          <div>
            <Productuser_review />
          </div>
        )}
      </div>
    </div>
  );
};

export default Detail_tab;
